/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './static/assets/scss/fontawesome.min.css'
import './static/assets/scss/bootstrap.scss'
import './static/assets/scss/animate.scss'
import './static/assets/scss/themify.scss'
import './static/assets/scss/color1.scss'
import './static/assets/scss/landing-page.scss'
import './static/assets/scss/slick.scss'
import './static/assets/scss/slick-theme.scss'

// import CMS from 'netlify-cms';
// import CollectionsList from './static/cms/components/'

// CMS.registerWidget('CollectionsList', CollectionsList);

export const onServiceWorkerUpdateReady = () => window.location.reload(true)

if ('serviceWorker' in navigator && 'Notification' in window) {

    function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
    }

    Notification.requestPermission(function(result) {
        if(result === 'granted') {
            console.log('granted!');
            var reg = null
            navigator.serviceWorker.ready.then((sw) => {
            reg = sw
            return sw.pushManager.getSubscription()
        }).then((sub) => {
            if(sub === null) {
                const publicVapidKey = 'BO_pwDKGj8eqFY3QCYGiyq9pQJtuD9gTzYOANF1CSENcPcB_LhcpvvZXLbSgRUpmj4BccF7w2IUSBigBUvF5Ysk';
                const convertedPublicVapidKey = urlBase64ToUint8Array(publicVapidKey)
                return reg.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: convertedPublicVapidKey,
                });
            }
            return null
        }).then((newSub) => {
            if(newSub) {
                // console.log('new sub', JSON.stringify(newSub))
                fetch('/.netlify/functions/subscribe', {
                    method: 'POST',
                    body: JSON.stringify(newSub),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then((value) => {
                    new Notification('subscribed successfully!')
                }).catch((err) => {})
            }
        })
        }
    })
}